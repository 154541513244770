import React from "react";
import Head from "next/head";
import Script from "next/script";

const seo_title = "Make purchases with Crypto | Moonsworth";
const seo_description = "Fast and secure way to purchase store credit on your favorite Moonsworth services, such as Lunar Client, using major cryptocurrencies.";

const Layout = ({ children }: React.ComponentPropsWithoutRef<"div">) => {
	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="keywords" content="moonsworth, lunar client, minecraft, crypto, cryptocurrency" />
				<meta name="robots" content="index, follow" />
				<meta name="theme-color" content="#0B0B0B" />
				<meta name="language" content="English" />
				<meta name="description" content={seo_description} />
				<title>{seo_title}</title>
				<link rel="icon" type="image/png" href="/assets/images/favicon.ico" />

				{/* OpenGraph SEO */}
				<meta property="og:site_name" content="Moonsworth, LLC" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={seo_title} />
				<meta property="og:description" content={seo_description} />

				{/* Twitter SEO */}
				<meta name="twitter:domain" content="crypto.moonsworth.com" />
				<meta name="twitter:site" content="@MoonsworthLLC" />
				<meta name="twitter:title" content={seo_title} />
				<meta name="twitter:description" content={seo_description} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:image" content="https://crypto.lunarclient.com/assets/images/card.webp" />

				{/* Google */}
				<meta name="google" content="nositelinkssearchbox" />
				<meta name="google-site-verification" content="Svf0OjPPpUgIYIH0KwzE7aiq9zmF8ASqjUhqsV877fI" />

			</Head>

			{children}

			{/* Google Tag Manager */}
			<Script id="google-analytics" strategy="lazyOnload">
				{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','GTM-MZD6N6S');`}
			</Script>

			<noscript
				dangerouslySetInnerHTML={{
					__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MZD6N6S" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
				}}
			/>

			{/* CookiePro CMP */}
			<Script
				src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
				type="text/javascript"
				charSet="UTF-8"
				data-domain-script="7e9e0fd8-1e93-4893-9644-f6e411f43ac8"
				strategy="lazyOnload"
			></Script>
			<Script id="cp-optanon">
				{`function OptanonWrapper() { }`}
			</Script>
		</>
	);
};

export default Layout;
