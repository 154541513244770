import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faInstagram, faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
	<footer>
		{/* Moonsworth */}
		<div>
			&copy; Moonsworth LLC, {new Date().getFullYear()}
		</div>
		{/* Socials */}
		<div className="socials">
			{/* Privacy Policy */}
			<a href="https://www.lunarclient.com/privacy" aria-label="Moonsworth Privacy Policy" target="_blank" rel="noreferrer">
				Privacy Policy
			</a>
			{/* Cookie Settings */}
			<a id="ot-sdk-btn" className="ot-sdk-show-settings" aria-label="Open Cookie Preference Center" href="#">
				Cookie Settings
			</a>
			{/* Moonsworth Twitter */}
			<a href="https://twitter.com/Moonsworth" aria-label="Moonsworth Twitter" target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={faTwitter} size="sm" />
			</a>
			{/* Lunar Client Telegram */}
			<a href="https://t.me/LunarClient" aria-label="Lunar Client Telegram" target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={faTelegram} size="sm" />
			</a>
			{/* Lunar Client Discord */}
			<a href="https://discord.com/invite/LunarClient" aria-label="Lunar Client Discord" target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={faDiscord} size="sm" />
			</a>
			{/* Lunar Client Instagram */}
			<a href="https://www.instagram.com/lunarclientofficial" aria-label="Lunar Client Instagram" target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={faInstagram} size="sm" />
			</a>
		</div>
	</footer>
);

export default Footer;
